/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const getcustomerWishlistShowPopupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getcustomerWishlistShowPopup"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist_show_popup"}}]}}]}}]} as unknown as DocumentNode<getcustomerWishlistShowPopupQuery, getcustomerWishlistShowPopupQueryVariables>;
export type getcustomerWishlistShowPopupQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type getcustomerWishlistShowPopupQuery = { customer?: { wishlist_show_popup?: boolean | null } | null };
