import { createContext, useContext, useReducer, useState } from 'react';
import Loading from '../components/Loading';

type LoadingContextState = {
  loading: boolean;
  setLoading: (loading: boolean) => void
}

const LoadingContext = createContext<LoadingContextState>({
  loading: false,
  setLoading: (loading) => { },
});

export const useLoading = () => useContext(LoadingContext);

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {loading && <Loading />}
      {children}
    </LoadingContext.Provider>
  );
}




