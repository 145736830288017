import Script from 'next/script'

const SearchSchema = () => (
  <Script id='schema-organization' strategy='beforeInteractive' type='application/ld+json'>
    {JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'WebSite',
      name: 'اردمی',
      url: process.env.NEXT_PUBLIC_SITE_URL,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${process.env.NEXT_PUBLIC_SITE_URL}/search/{search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
    })}
  </Script>
)

export default SearchSchema
