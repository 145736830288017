import React, { createContext, useContext, useMemo, useState } from "react"
// eslint-disable-next-line import/no-cycle
import { AddProductsToCompareSnackbar } from "./AddProductsToCompareSnackbar";

type MessageProduct = {
  name: string;
  image_url: string;
}

type CompareMessageContextType = {
  displayMessageBar: boolean
  setDisplayMessageBar: (v: boolean) => void
  product: MessageProduct | null
  setProduct: (v: MessageProduct) => void
}
export const CompareMessageContext = createContext<CompareMessageContextType>({
  displayMessageBar: false,
  setDisplayMessageBar: () => { },
  product: null,
  setProduct: () => { }
});

export const useCompareMessage = () => useContext(CompareMessageContext)
type CompareMessageProviderProps = {
  children: React.ReactNode;
}
export default function CompareMessageProvider(props: CompareMessageProviderProps) {
  const { children } = props;
  const [displayMessageBar, setDisplayMessageBar] = useState<boolean>(false);
  const [product, setProduct] = useState<MessageProduct | null>(null)

  return (
    <CompareMessageContext.Provider value={useMemo(() => ({
      displayMessageBar,
      setDisplayMessageBar,
      product,
      setProduct
    }), [product, displayMessageBar]
    )}>
      <AddProductsToCompareSnackbar />
      {children}
    </CompareMessageContext.Provider >
  )
}