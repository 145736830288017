const loadGoftinoWidget = () => {
    const scriptId = "pkcdc2";
    const scriptUrl = `https://www.goftino.com/widget/${scriptId}`;
    
    const createScriptElement = (src: string) => {
      const script = document.createElement("script");
      script.async = true;
      script.src = src;
      document.head.appendChild(script);
    };
  
    const localStorageKey = `goftino_${scriptId}`;
    const localStorageValue = localStorage.getItem(localStorageKey);
    const scriptSrc = localStorageValue ? `${scriptUrl}?o=${localStorageValue}` : scriptUrl;
  
    const onLoad = () => createScriptElement(scriptSrc);
  
    if (document.readyState === "complete") {
      onLoad();
    } else {
      window.addEventListener("load", onLoad);
    }
  };
  
  export default loadGoftinoWidget;