import { useCartQuery } from '@graphcommerce/magento-cart/hooks/useCartQuery'
import { useRouter } from 'next/router'
import { ReactElement, useEffect } from 'react'
import useNewCart from '../../customHooks/useNewCart'
import { CartPageDocument } from '../../graphql/checkout/CartPage.gql'

type CheckCartProps = {
  children: ReactElement
}

// eslint-disable-next-line import/no-default-export
export default function CheckCart(props: CheckCartProps) {
  const { children } = props
  const router = useRouter()
  const { clearCart } = useNewCart()

  const { error, loading } = useCartQuery(CartPageDocument, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    async function fetchData() {
      try {
        await clearCart()
      } catch (e) {
        console.error(e)
      }
    }
    if (error && !loading) {
      fetchData()
    }
  }, [clearCart, error, loading, router])

  return <>{children}</>
}
