import { State, Action } from './types';

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set':
      return { content: action.value.content, title: action.value.title, open: true, position: action.value.position, width: action.value.width };
    case 'close':
      return { content: null, title: null, open: false, position: 'right', width: null };
    default:
      return state;
  }
};
export default reducer;




