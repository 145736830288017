import LoadingIcon from '../icons/Loading';
// eslint-disable-next-line import/no-default-export
export default function Loading() {
  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 bg-white/80 flex justify-center items-center z-[9999]">
      <span>
        <LoadingIcon width={56} height={56} />
      </span>
    </div>
  )
}