import { useApolloClient } from '@graphcommerce/graphql'
import { useAssignCurrentCartId } from '@graphcommerce/magento-cart'
import { CreateEmptyCartDocument } from '@graphcommerce/magento-cart/hooks/CreateEmptyCart.gql'

export default function useNewCart() {
  const client = useApolloClient()
  const assignCurrentCartId = useAssignCurrentCartId()

  const clearCart = async () => {
    const { data } = await client.mutate({ mutation: CreateEmptyCartDocument })
    if (!data?.createEmptyCart) throw Error(i18n._(/* i18n */ 'Could not create an empty cart'))
    assignCurrentCartId(data.createEmptyCart)
  return data.createEmptyCart
  }
  return { clearCart }
}
