import { ScriptState, Action, ScriptAction } from './types';

const scriptReducer = (state: ScriptState, action: ScriptAction) => {
  switch (action.type) {
    case 'set':
      return { scripts: action.value.scripts, styles: action.value.styles };
    default:
      return state;
  }
};
export default scriptReducer;




