'use client'

import React, { useReducer } from "react";
import reducer from "./reducer";
import scriptReducer from "./scriptReducer";
import { ContextType, ScriptState, State } from "./types";



const initialState: ContextType = {
  open: false,
  title: null,
  content: null,
  position: 'right',
};

export const ModalContext = React.createContext(initialState);

export function ModalContextValue() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return {
    position: state.position,
    title: state.title,
    open: state.open,
    content: state.content,
    width: state.width,
    close: () => dispatch({ type: 'close' }),
    set: (value: State['content'] & State['title']) => dispatch({ type: 'set', value }),
  };
}


const initialScript: {
  styles: any[],
  scripts: any[]
} = {
  styles: [],
  scripts: []
};

export const ScriptContext = React.createContext(initialScript);

export function ScriptContextValue() {
  const [state, dispatch] = useReducer(scriptReducer, initialScript);
  return {
    styles: state.styles,
    scripts: state.scripts,
    set: (value: ScriptState) => dispatch({ type: 'set', value }),

  };
}