import { createContext, useContext, useEffect, useState } from 'react'
import {
  wishlistCategoriesDocument,
  wishlistCategoriesQuery,
} from '../graphql/wishlist/wishlistCategories.gql'
import { useCustomerQuery } from '@graphcommerce/magento-customer'
import { getCustomerWishlistIdDocument } from '../graphql/wishlist/getCustomerWishlistId.gql'
import { getcustomerWishlistShowPopupDocument } from '../graphql/wishlist/getcustomerWishlistShowPopup.gql'

type WishlistContextState = {
  items: wishlistCategoriesQuery['wishlistCategories']
  setItems: (items: wishlistCategoriesQuery['wishlistCategories']) => void
  handleRefetch: () => void
  handleRefreshPopup: () => void
  wishlistLoading: boolean
  wishlistId: string | null
  showPopup: boolean | null | undefined
  totalCount: number
}
const WishListContext = createContext<WishlistContextState>({
  items: null,
  setItems: (items) => {},
  handleRefetch: () => {},
  handleRefreshPopup: () => {},
  wishlistLoading: false,
  wishlistId: null,
  showPopup: false,
  totalCount: 0,
})

export const useWishlist = () => useContext(WishListContext)

export default function WishListProvider({ children }) {
  const [items, setItems] = useState<wishlistCategoriesQuery['wishlistCategories']>()
  const [wishlistId, setwishlistId] = useState<null | string>(null)
  const [showPopup, setShowPopup] = useState<boolean | null>()
  const [totalCount, setTotalCount] = useState<number>()
  const { data, loading, refetch } = useCustomerQuery(wishlistCategoriesDocument, {
    skip: true,
  })
  const { data: showPopupData, refetch: refetchShowPopupData } = useCustomerQuery(
    getcustomerWishlistShowPopupDocument,
    { fetchPolicy: 'network-only' },
  )
  const { data: wishlistIdData } = useCustomerQuery(getCustomerWishlistIdDocument)
  useEffect(() => {
    setItems(data?.wishlistCategories)

    setTotalCount(() => {
      return (
        data?.wishlistCategories?.reduce<string[]>(
          (c, i) => [...c, ...(i?.products?.map((p) => p?.wishlist_item_id!) ?? [])],
          [],
        ) ?? []
      ).length
    })
  }, [data?.wishlistCategories])

  useEffect(() => {
    if (wishlistIdData?.customer?.wishlist_v2?.id) {
      setwishlistId(wishlistIdData?.customer?.wishlist_v2?.id)
      refetch()
    }
  }, [wishlistIdData?.customer?.wishlist_v2?.id])

  useEffect(() => {
    setShowPopup(
      showPopupData?.customer?.wishlist_show_popup === null
        ? true
        : showPopupData?.customer?.wishlist_show_popup,
    )
  }, [showPopupData?.customer?.wishlist_show_popup])

  const handleRefetch = () => {
    refetch()
  }

  const handleRefreshPopup = () => {
    refetchShowPopupData()
  }
  return (
    <WishListContext.Provider
      value={{
        items,
        setItems,
        handleRefetch,
        wishlistLoading: loading,
        wishlistId,
        showPopup,
        totalCount: totalCount!,
        handleRefreshPopup,
      }}
    >
      {children}
    </WishListContext.Provider>
  )
}
